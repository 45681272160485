.pac-container {
  z-index: 100000 !important;
}

#react-select-2-listbox {
  z-index: 1000 !important;
  background-color: white !important;
}

body[data-sidebar="dark"].vertical-collpsed {
  min-height: 100vh;
}

.search-label {
  float: left !important;
}

.css-13cymwt-control {
  min-height: 36px !important;
  max-height: 36px !important;
}

.table > :not(caption) > * > * {
  background-color: transparent !important;
}

.blinking-row {
  animation: blinkingBackgroundDanger 2s infinite;
}

.blinking-row-warning {
  animation: blinkingBackgroundWarning 2s infinite;
}

.mobile-menu .vertical-menu {
  display: none;
}

.mobile-menu .main-content {
  margin-left: 0;
}

@keyframes blinkingBackgroundDanger {
  0% {
    background-color: rgba(244, 106, 106, 0.5);
  }
  50% {
    background-color: transparent;
  }
  100% {
    background-color: rgba(244, 106, 106, 0.5);
  }
}

@keyframes blinkingBackgroundWarning {
  0% {
    background-color: rgba(241, 180, 76, 0.25);
  }
  50% {
    background-color: transparent;
  }
  100% {
    background-color: rgba(241, 180, 76, 0.25);
  }
}

body[data-is-mobile="true"] {
  font-size: 1.1rem !important;
}
