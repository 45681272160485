#appointments-page {
  .page-content {
    padding: 70px 12px 60px;
  }

  .container-fluid {
    padding: 0;
  }

  .table-responsive {
    margin-top: 1rem;
    background-color: #fff;
    border-radius: 0.25rem;
    box-shadow: 0 0 1px rgba(0,0,0,0.1);
  }

  .table {
    margin-bottom: 0;
  }

  .pagination {
    margin: 1rem;
  }
} 